import { action, computed, observable, runInAction } from 'mobx';
import AbstractStore from '../../store/AbstractStore';
import { PERMISSION_ALLOW, PERMISSION_VIEW, RESOURCE_DASHBOARD_BUTTON_VELLO, RESOURCE_LEFT_NAV_PRACTICE_USERS_MNGMNT } from '../../types/users-types';
import {
  MessageMultipleServers, MessageNoServer,
  MessagePracticeUpgradeLocked,
  MessageServerOffline,
  MessageServerStatusUnavailable,
  MessageSupportAssistanceRequired,
  MessageWithoutPracticeAdmin,
  MessageSendVerificationCode,
  MessageNoUpgradeRequired,
  MessageWithPracticeAdmin,
  VelloIntegrationUnavailable,
  MessageNoSupportContact,
  MessageSupportStatusUnknown,
} from './renderers';
import React from 'react';
import VerificationCodeModalContent from './VerificationCodeModalContent';
import { PracticeConfig } from '../../types/practice-types';
import { refreshServerUpgradeStatusConfig } from '../../config/app';
import { sleep } from '../../utils/object-utils';
import { vendorsUrl } from '../../config/vendors';
import { checkSinglePermission } from '../../security/security-hook';

interface Loaders {
  page: boolean;
  scheduleNow?: boolean;
  userManagement?: boolean;
  vello?: boolean;
}

export default class DashboardStore extends AbstractStore {
  @observable loaders: Loaders = {
    page: true,
  };

  @observable buttonsDisabled = false;

  @action
  public async init(): Promise<void> {
    runInAction(() => {
      this.loaders.page = true;
      this.buttonsDisabled = false;
    });
    await this.root.globalStore.endUpgradeTaskSessionIfStarted();
    if (this.root.globalStore.userSaps.length === 0) {
      await this.fetchCurrentUserDetails();
    }
    runInAction(() => this.loaders.page = false);
  }

  @action
  public async fetchCurrentUserDetails(): Promise<void> {
    try {
      runInAction(() => {
        this.loaders.page = true;
      });
      await this.root.apiStore.fetchCurrentUserDetails();
      if (this.root.globalStore.userSaps && this.root.globalStore.userSaps.length > 0) {
        runInAction(() => this.root.globalStore.selectedSap = this.root.globalStore.userSaps[0]);
      }
    } catch {
      // ignore
    }
  }

  @computed
  public get sapIds(): string[] {
    return this.root.globalStore.userSaps.map(a => a.id) || [];
  }

  @action
  public async checkAllowStartScheduleUpgrade(index: number, isAllowed: boolean, bypassAcknowledgements: boolean): Promise<void> {
    runInAction(() => this.loaders.scheduleNow = true);
    const sap = this.root.globalStore.userSaps[index];
    const sapId = sap?.id;
    if (!sapId) return;
    if (isAllowed) {
      return this.startScheduleUpgrade(index, bypassAcknowledgements);
    } else {
      const result = await this.root.apiStore.checkSapUsersForResourcePermission(sapId, RESOURCE_LEFT_NAV_PRACTICE_USERS_MNGMNT, PERMISSION_VIEW);
      this.root.globalStore.showOkPopup(
        'Let\'s get started ...',
        React.createElement(result ? MessageWithPracticeAdmin : MessageWithoutPracticeAdmin),
      );
    }
    runInAction(() => this.loaders.scheduleNow = false);
  }

  @action
  public async verificationCodeModal(sapId: string): Promise<void> {
    try {
      await this.root.apiStore.updatePracticeAdminVerificationCode(sapId);
      this.root.globalStore.showOkCancelPopup(
        'Enter Your Code',
        React.createElement(VerificationCodeModalContent, { sapId }),
        'Submit',
        'Resend Code',
        () => {},
      );
    } catch (e: any) {
      this.showPracticeRelatedPopup(e);
    }
  }

  @action
  private async startScheduleUpgrade(index: number, bypassAcknowledgements: boolean): Promise<void> {
    runInAction(() => {
      this.buttonsDisabled = true;
      this.loaders.scheduleNow = true;
      this.root.globalStore.practiceSelectorDisabled = true;
    });
    try {
      const affiliation = this.root.globalStore.userSaps[index];
      const sapId = affiliation?.id;
      if (!sapId) return;
      await this.root.globalStore.fetchEventIntervals();
      await this.root.globalStore.fetchPracticeData(this.sapIdChanged, true);
      this.root.globalStore.isLoggedIn = true;
      const practice = this.root.globalStore.practice;
      if (practice && practice.isLocked && practice.lockedByEmail !== this.root.apiStore.userEmail) {
        this.root.globalStore.showOkPopup(
          'Cornerstone Upgrade',
          React.createElement(MessagePracticeUpgradeLocked, { userName: practice.lockedByName })
        );
      } else if (practice) {
        await this.checkServerUpgradeStateAndRedirect(practice, bypassAcknowledgements);
      }
    } catch (e: any) {
      this.showPracticeRelatedPopup(e);
    }
    runInAction(() => {
      this.buttonsDisabled = false;
      this.loaders.scheduleNow = false;
      this.root.globalStore.practiceSelectorDisabled = false;
    });
  }

  @action
  public async checkAllowUserManagement(index: number, isAllowed: boolean): Promise<void> {
    runInAction(() => this.loaders.userManagement = true);
    const sap = this.root.globalStore.userSaps[index];
    const sapId = sap?.id;
    if (!sapId) return;
    if (isAllowed) {
      this.navigate('/practice/user-management');
    } else {
      if (await this.isPracticeAdminExists(sapId)) {
        this.root.globalStore.showOkPopup(
          'Let\'s get started ...',
          React.createElement(MessageWithPracticeAdmin),
        );
      } else {
        const machineGroupExists = await this.root.apiStore.checkMachineGroup(sapId, false);
        if (!machineGroupExists) {
          this.root.globalStore.showOkPopup(
            'Support Assistance Required',
            React.createElement(MessageSupportAssistanceRequired),
          );
        } else {
          this.root.globalStore.showOkCancelPopup(
            'Create Practice Administrator',
            React.createElement(MessageSendVerificationCode, { practiceName : sap.name }),
            'Send Code',
            'Cancel',
            () => this.verificationCodeModal(sap.id),
            () => {},
            false,
            false
          );
        }
      }
    }
    runInAction(() => this.loaders.userManagement = false);
  }

  @action
  public redirectToTrupanion(index: number): void {
    const sap = this.root.globalStore.userSaps[index];
    window.location.href = `${vendorsUrl()}?sapId=${sap.id}`;
  }

  @action
  public async launchVelloAuthPage(index: number): Promise<void> {
    const sap = this.root.globalStore.userSaps[index];
    const check = checkSinglePermission(this.root.apiStore.userGlobalPermissions, RESOURCE_DASHBOARD_BUTTON_VELLO, PERMISSION_ALLOW);
    if (!check && !sap.myIdexxAdmin) {
      this.root.globalStore.showOkCancelPopup(
        'Integration Unavailable',
        React.createElement(VelloIntegrationUnavailable),
        'OK',
        'Sign Out',
        () => {},
        () => this.root.globalStore.signOut(),
        false,
        true,
        true,
      );
      return;
    }
    runInAction(() => this.loaders.vello = true);
    try {
      const response = await this.root.apiStore.getVelloEnrollmentAndPracticeAuthUrl(sap.id);
      if (response.status === 'ENROLLED') {
        window.open(response.authLink, '_blank', 'noopener,noreferrer');
      } else if (response.status === 'NOT_ENROLLED') {
        this.root.globalStore.showOkPopup(
          'Vello is not enrolled',
          'Not enrolled',
        );
      } else if (response.status === 'ENROLLMENT_ERROR') {
        this.root.globalStore.showOkPopup(
          'Vello Enrollment Error',
          'Enrollment error',
        );
      }
    } catch (e: any) {
      this.root.globalStore.showOkPopup(
        'Connection Error',
        'An unexpected error occurred, please try again.',
      );

    } finally {
      runInAction(() => this.loaders.vello = false);
    }
  }

  private async checkServerUpgradeStateAndRedirect(practice: PracticeConfig, bypassAcknowledgements: boolean): Promise<void> {
    if (!practice.serverAgentId || !practice.serverStatus) {
      this.navigate(bypassAcknowledgements ? '/check-qualifications' : '/acknowledgments');
      return;
    }
    switch (practice.serverStatus) {
      case 'UPGRADE_NOT_SCHEDULED': this.navigate(bypassAcknowledgements ? '/check-qualifications' : '/acknowledgments'); break;
      case 'UPGRADE_PENDING': this.navigate('/confirmation'); break;
      case 'UPGRADE_COMPLETE': this.showNoUpgradeRequiredPopup(); break;
      case 'OFFLINE': this.showUnableToDetermineStatusPopup(); break;
      case 'REFRESH_IN_PROGRESS': {
        for (let i = 0; i < refreshServerUpgradeStatusConfig.maxAttempts; i++) {
          if (i > 0) await sleep(refreshServerUpgradeStatusConfig.interval);
          try {
            const state = await this.root.apiStore.getAgentUpgradeState(this.sapId, practice.serverAgentId);
            if (state === 'UPGRADE_FAILED') {
              this.navigate('/confirmation');
              return;
            }
            if (state === 'UPGRADE_COMPLETE') {
              this.showNoUpgradeRequiredPopup();
              return;
            }
          } catch { /* ignore */ }
        }
        this.showUnableToDetermineStatusPopup();
      }
    }
  }

  private showUnableToDetermineStatusPopup(): void {
    this.root.globalStore.showOkPopup(
      'Unable To Determine Upgrade Status',
      React.createElement(MessageServerStatusUnavailable)
    );
  }

  private showNoUpgradeRequiredPopup(): void {
    this.root.globalStore.showOkPopup(
      'No Upgrade Required',
      React.createElement(MessageNoUpgradeRequired)
    );
  }

  private isPracticeAdminExists(sapId: string): Promise<boolean> {
    return this.root.apiStore.checkSapUsersForResourcePermission(sapId, RESOURCE_LEFT_NAV_PRACTICE_USERS_MNGMNT, PERMISSION_VIEW);
  }

  private showPracticeRelatedPopup(e: any): void {
    if (e.message?.includes('Support Contract Required')) {
      this.root.globalStore.showOkPopup('Support Contract Required', React.createElement(MessageNoSupportContact));
    } else if (e.message?.includes('Support Status Unknown')) {
      this.root.globalStore.showOkPopup('Support Status Unknown', React.createElement(MessageSupportStatusUnknown));
    } else if (e.message?.includes('Server offline')) {
      this.root.globalStore.showOkPopup('Server Offline', React.createElement(MessageServerOffline));
    } else if (e.message?.includes('Multiple servers detected')) {
      this.root.globalStore.showOkPopup('Multiple servers found', React.createElement(MessageMultipleServers));
    } else if (e.message?.includes('No server detected')) {
      this.root.globalStore.showOkPopup('No Server Detected', React.createElement(MessageNoServer));
    } else {
      this.root.globalStore.showOkPopup('Error', e.message);
    }
  }

  @action
  public close(): void {
    this.loaders = {
      page: true,
    };
    this.buttonsDisabled = false;
  }
}
